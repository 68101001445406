import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router'
import { Link } from 'react-router-dom'

import Footer from '../aux/Footer'

export default function AuthLayout({ children, headerRight } : { children?: React.ReactNode, headerRight?: React.ReactNode }) {
  return (
    <>
      <Container
        className="auth-layout mt-3 mt-sm-4 d-flex flex-column flex-auto"
        style={{ minHeight: '100vh' }}
      >
        <Row className="d-flex flex-row align-items-center mb-5">
          <Col className={headerRight === undefined ? 'text-center' : ''}>
            <Link to="/">
              <img
                src="/images/logo.png"
                srcSet="/images/logo@2x.png 2x, /images/logo@3x.png 3x"
                style={{ height: '36px' }}
                alt="Bageera"
              />
            </Link>
          </Col>

          { headerRight !== undefined && (
            <Col xs="auto">
              { headerRight }
            </Col>
          ) }
        </Row>

        <div className="flex-auto d-flex flex-row align-items-center my-3">
          <Row className="flex-auto justify-content-md-center">
            { children ?? <Outlet /> }
          </Row>
        </div>

        <div className="pt-4 pt-md-6" />
      </Container>
      
      <Container>
        <Footer />
      </Container>
    </>
  )
}
