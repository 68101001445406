import { Col, Container, Row } from 'react-bootstrap'

export default function Well() {
  return (
    <div
      id="why-agriculture"
      className="bg-white py-5 py-md-6"
    >
      <Container className="px-0 px-sm-3">
        <div
          className="rounded-3 rounded-xs-0 px-4 py-5 p-sm-5 p-lg-6" 
          style={{ background: '#e4eaf0' }}
        >
          <Row className="justify-content-between my-4">
            <Col
              xs={12}
              xl={4}
            >
              <h3 className="display-4">
                Sustainable agriculture might only be the beginning, but it's a pretty good place to start...
              </h3>
            </Col>

            <Col
              xs={12}
              xl={8}
              className="pt-3 pt-xl-0 ps-xl-6"
              style={{ color: '#6C757D' }}
            >
              <p className="display-5 font-weight-light">
                Agricultural land ensures the unique combination of financial, environmental and social returns​.
              </p>

              <Row className="display-6 font-weight-light mt-5">
                <Col
                  xs={12}
                  md={6}
                  className="d-flex align-items-center pe-5 mb-3 mb-lg-4"
                >
                  <div className="me-4 flex-shrink-0">
                    <img
                      src="/images/home/intro1.png"
                      srcSet="/images/home/intro1@2x.png 2x, /images/home/intro1@3x.png 3x"
                      alt=""
                    />
                  </div>

                  <div>
                    Strong financial returns
                  </div>
                </Col>

                <Col
                  xs={12}
                  md={6}
                  className="d-flex align-items-center pe-5 mb-3 mb-lg-4"
                >
                  <div className="me-4 flex-shrink-0">
                    <img
                      src="/images/home/intro2.png"
                      srcSet="/images/home/intro2@2x.png 2x, /images/home/intro2@3x.png 3x"
                      alt=""
                    />
                  </div>

                  <div>
                    Historical asset stability and diversification
                  </div>
                </Col>

                <Col
                  xs={12}
                  md={6}
                  className="d-flex align-items-center pe-5 mb-3 mb-lg-0"
                >
                  <div className="me-4 flex-shrink-0">
                    <img
                      src="/images/home/intro4.png"
                      srcSet="/images/home/intro4@2x.png 2x, /images/home/intro4@3x.png 3x"
                      alt=""
                    />
                  </div>

                  <div>
                    Unique potential to mitigate climate change
                  </div>
                </Col>



                <Col
                  xs={12}
                  md={6}
                  className="d-flex align-items-center pe-5 mb-3 mb-lg-0"
                >
                  <div className="me-4 flex-shrink-0">
                    <img
                      src="/images/home/intro3.png"
                      srcSet="/images/home/intro3@2x.png 2x, /images/home/intro3@3x.png 3x"
                      alt=""
                    />
                  </div>

                  <div>
                    Direct support to the rural economies
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
