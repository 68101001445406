import { Form } from 'react-bootstrap'

export default function ErrorFeedback({ error }: { error?: string|string[]|undefined|null }) {
  if (error) {
    return (
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
    )
  }

  return null
}
