import { Col, Container, Row } from 'react-bootstrap'

export default function Approach() {
  return (
    <Container className="py-6">
      <h1 className="display-1 text-center mb-6">
        We take a new approach to realise the <span className="text-muted">full value of natural capital assets</span>
      </h1>

      <Row>
        <Col
          xs={12}
          lg={5}
        >
          <h4 className="display-4 text-center mb-5 mb-sm-6 px-lg-5">
            Accounting for traditional revenue streams...
          </h4>
        </Col>

        <Col
          xs={12}
          lg={1}
          className="d-none d-lg-block"
        ></Col>

        <Col
          xs={12}
          lg={6}
          className="d-none d-lg-block"
        >
          <h4 className="display-4 text-center mb-5 pb-5 ps-3">
            ... while enabling long-term benefits that generate impact and income
          </h4>
        </Col>
      </Row>

      <div
        style={{
          marginLeft: '-0.75rem',
          marginRight: '-0.75rem'
        }}
      >
        <div className="approach-map">
          <div className="returns-line" />

          <div className="sum-line" />
        
          <div className="return-card rents">
            <Row className="align-items-center my-2">
              <Col
                xs={12}
                style={{ fontSize: '17px', fontWeight: 500 }}
              >
                Annual Cash Earnings
              </Col>

              <Col
                xs={12}
                className="text-muted mt-2"
                style={{ fontSize: '14px', fontWeight: 300 }}
              >
                Passive income streams from the current asset yields
              </Col>
            </Row>
          </div>

          <div className="return-card appreciation">
            <Row className="align-items-center my-2">
              <Col
                xs={12}
                style={{ fontSize: '17px', fontWeight: 500 }}
              >
                Land Appreciation Potential
              </Col>

              <Col
                xs={12}
                className="text-muted mt-2"
                style={{ fontSize: '14px', fontWeight: 300 }}
              >
                Increases in the underlying value of the assets
              </Col>
            </Row>
          </div>

          <div
            className="sum-card bg-white rounded-circle px-4 py-3 d-flex flex-row align-items-center justify-content-center"
          >
            +
          </div>

          <div className="bageera-card bg-primary rounded-circle px-4 py-3 d-flex flex-row align-items-center justify-content-center">
            <img
              src="/images/home/sign-white.png"
              srcSet="/images/home/sign-white@2x.png 2x, /images/home/sign-white@3x.png 3x"
              alt="Bageera"
            />
          </div>

          <div className="circle-line" />

          <div className="circle-cards">
            <div className="position-relative">
              <div style={{ top: '-50px', left: '-260px' }}>
                <img
                  src="/images/home/circle1.png"
                  srcSet="/images/home/circle1@2x.png 2x, /images/home/circle1@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Premium on organic products
                </p>
              </div>

              <div style={{ top: '-198.5px', left: '-198.5px' }}>
                <img
                  src="/images/home/circle2.png"
                  srcSet="/images/home/circle2@2x.png 2x, /images/home/circle2@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Decreased fuel &amp; energy costs
                </p>
              </div>

              <div style={{ top: '-260px', left: '-50px' }}>
                <img
                  src="/images/home/circle3.png"
                  srcSet="/images/home/circle3@2x.png 2x, /images/home/circle3@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Reduced machinery
                </p>
              </div>

              <div style={{ top: '-198.5px', left: '98.5px' }}>
                <img
                  src="/images/home/circle4.png"
                  srcSet="/images/home/circle4@2x.png 2x, /images/home/circle4@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Less synthetic fertiliser
                </p>
              </div>

              <div style={{ top: '-50px', left: '160px' }}>
                <img
                  src="/images/home/circle5.png"
                  srcSet="/images/home/circle5@2x.png 2x, /images/home/circle5@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Carbon certificates
                </p>
              </div>

              <div style={{ top: '98.5px', left: '98.5px' }}>
                <img
                  src="/images/home/circle6.png"
                  srcSet="/images/home/circle6@2x.png 2x, /images/home/circle6@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Higher water holding capacity
                </p>
              </div>

              <div style={{ top: '160px', left: '-50px' }}>
                <img
                  src="/images/home/circle7.png"
                  srcSet="/images/home/circle7@2x.png 2x, /images/home/circle7@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Higher nutrient content
                </p>
              </div>

              <div style={{ top: '98.5px', left: '-198.5px' }}>
                <img
                  src="/images/home/circle8.png"
                  srcSet="/images/home/circle8@2x.png 2x, /images/home/circle8@3x.png 3x"
                  alt=""
                  className="mb-1"
                />

                <p className="text-center">
                  Increased biodiversity
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Row>
        <Col
          xs={12}
          lg={6}
          className="d-block d-lg-none"
        >
          <h4 className="display-4 text-center mt-5 ps-3">
            ... while enabling long-term benefits that generate impact and increase your yields
          </h4>
        </Col>
      </Row>
    </Container>
  )
}
