import { ReactNode, useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { Outlet } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import Footer from '../aux/Footer'

enum HomeSections {
  WhyAgriculture = 'why-agriculture',
  HowItWorks = 'how-it-works'
}

export default function PublicLayout({ children } : { children?: ReactNode }) {
  const location = useLocation()

  // -------------------------- General scrolling --------------------------

  const [scroll, setScroll] = useState<boolean>(false)
  const [navbarShow, setNavbarShow] = useState<boolean>(false)

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 25
      
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    }

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [scroll])

  // ------------------------- Home-only scrolling -------------------------

  const [homeSection, setHomeSection] = useState<HomeSections|null>(null)

  useEffect(() => {
    if (location.pathname !== '/') {
      setHomeSection(null)
      return
    }

    setHomeSection(HomeSections.WhyAgriculture)

    const onScroll = () => {
      Object.values(HomeSections).reverse().every(sectionId => {
        const element = document.getElementById(sectionId)

        const scroll = sectionId !== 'why-agriculture' && element !== undefined && element !== null
          ? window.scrollY >= element.offsetTop-1
          : true

        if (scroll) {
          setHomeSection(sectionId)
          return false
        }

        return true
      })
    }

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [location.pathname])

  return (
    <>
      <Navbar
        expand="lg"
        className={scroll || navbarShow ? 'scroll-down' : ''}
        collapseOnSelect={true}
        fixed="top"
      >
        <Container className="px-sm-3">
          <Navbar.Toggle onClick={() => setNavbarShow(!navbarShow)} />

          <Link
            to="/"
            className="order-lg-first me-0 me-md-5"
            onClick={() => {
              if (location.pathname === '/') {
                window.scrollTo(0, 0)
              }
            }}
          >
            <img
              className="ms-3 ms-lg-0"
              src="/images/logo.png"
              srcSet="/images/logo@2x.png 2x, /images/logo@3x.png 3x"
              alt="Bageera"
              style={{ height: '36px' }}
            />
          </Link>

          <div className="order-lg-last ms-auto">
            <Link
              to="/register"
              className="btn btn-lg btn-primary rounded-pill py-2 ms-2"
            >
              <span className="py-1 d-block">
                Register
                
                <span className="d-none d-md-inline">
                  &nbsp;your interest
                </span>
              </span>
            </Link>
          </div>

          <Navbar.Collapse>
            <Nav className="ms-0 ms-lg-5">
              <Nav.Item>
                <HashLink to="/#why-agriculture">
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/' && homeSection === HomeSections.WhyAgriculture}
                  >
                    Why Agriculture?
                  </Nav.Link>
                </HashLink>
              </Nav.Item>

              <Nav.Item>
                <HashLink to="/#how-it-works">
                  <Nav.Link
                    as="span"
                    active={homeSection === HomeSections.HowItWorks}
                  >
                    How It Works
                  </Nav.Link>
                </HashLink>
              </Nav.Item>

              <Nav.Item>
                <Link
                  to="/about" 
                  onClick={() => {
                    if (location.pathname === '/about') {
                      window.scrollTo(0, 0)
                    }
                  }}
                >
                  <Nav.Link
                    as="span"
                    active={location.pathname === '/about'}
                  >
                    About Us
                  </Nav.Link>
                </Link>
              </Nav.Item>

              <Nav.Item>
                <HashLink to="/about#careers">
                  <Nav.Link
                    as="span"
                  >
                    Careers
                  </Nav.Link>
                </HashLink>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      { children ?? <Outlet /> }

      <Container>
        <Footer />
      </Container>
    </>
  )
}
