import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { useAppSelector, useAppDispatch } from './utils/hooks'
import { selectStatus, setStatusCode } from './utils/store/status'
import About from './views/About'
import Cookies from './views/Cookies'
import Home from './views/Home'
import Privacy from './views/Privacy'
import Register from './views/Register'
import Terms from './views/Terms'
import Loading from './views/aux/Loading'
import NotAuthorized from './views/errors/NotAuthorized'
import NotFound from './views/errors/NotFound'
import PublicLayout from './views/layouts/PublicLayout'

export default function App() {
  const dispatch = useAppDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(setStatusCode(200))
  }, [dispatch, location.key])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const status = useAppSelector(selectStatus)

  if (status.code === -1) {
    return (
      <Loading absolute={true} />
    )
  }

  if (status.code === 403) {
    return (
      <NotAuthorized />
    )
  }

  if (status.code === 404) {
    return (
      <NotFound />
    )
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PublicLayout />
          }
        >
          <Route
            path=""
            element={
              <Home />
            }
          />

          <Route
            path="about"
            element={
              <About />
            }
          />

          <Route
            path="terms"
            element={
              <Terms />
            }
          />

          <Route
            path="cookies"
            element={
              <Cookies />
            }
          />

          <Route
            path="privacy"
            element={
              <Privacy />
            }
          />
        </Route>

        <Route
          path="/register"
          element={
            <Register />
          }
        />

        <Route
          path="*"
          element={<NotFound />}
        />
      </Routes>
    </>
  )
}
