import Approach from '../components/home/Approach'
import Hero from '../components/home/Hero'
import HowItWorks from '../components/home/HowItWorks'
import Well from '../components/home/Well'
import WhyAgriculture from '../components/home/WhyAgriculture'
import WhyInvest from '../components/home/WhyInvest'

export default function Home() {
  return (
    <div>
      <Hero />

      <Well />

      <WhyAgriculture />

      <Approach />

      <HowItWorks />

      <WhyInvest />
    </div>
  )
}
