import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import HeroVideo from './HeroVideo'

export default function Hero() {
  return (
    <div className="pt-7 pb-0 pb-sm-5 pb-lg-6">
      <Container
        id="investing"
        className="d-flex flex-column flex-auto"
      >
        <Row className="d-flex align-items-center">
          <Col
            xs={12}
            md={6}
            className="ps-4 ps-sm-3 mb-5 mb-lg-0"
          >
            <h1 className="display-2 mb-5 pe-4">
              Investing in nature is <span className="text-muted">the growth story</span> of our generation
            </h1>

            <p className="lead text-secondary me-0 me-sm-6 me-md-0 me-lg-6 me-xl-7 mb-5">
              We unlock investment opportunities in natural capital assets. Starting with agricultural land, a unique way to create impact and generate financial returns.
            </p>

            <p>
              <Link
                to="/register"
                className="btn btn-primary btn-lg rounded-pill px-4"
              >
                Register your interest
              </Link>
            </p>
          </Col>
            
          <Col
            xs={12}
            md={6}
            className="align-items-end px-0 px-sm-3"
          >
            <HeroVideo />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
