import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from './index'

interface StatusState {
  code: number
}

const initialState: StatusState = {
  code: 200,
}

export const statusSlice = createSlice({
  name: 'status',

  initialState,

  reducers: {
    setStatusCode: (state, action: PayloadAction<number>) => {
      state.code = action.payload
    },
  },
})

export const { setStatusCode } = statusSlice.actions

export const selectStatus = (state: RootState) => state.status

export default statusSlice.reducer
