import { TouchEvent, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ArrowLeft, ArrowRight } from 'react-feather'
import { Link } from 'react-router-dom'

export default function HowItWorks() {
  const [section, setSection] = useState<number>(0)

  const [prevEnabled, setPrevEnabled] = useState<boolean>(false)
  const [nextEnabled, setNextEnabled] = useState<boolean>(true)

  const [xDown, setXDown] = useState<number|null>(null)
  const [yDown, setYDown] = useState<number|null>(null)

  const updateSection = (newSection: number) => {
    const stepsElement = document.getElementById('steps-wrapper')
      
    if (stepsElement === undefined || stepsElement === null) {
      return
    }
    
    const steps = document.getElementsByClassName('how-it-works-step')

    const newScroll = Math.min(344 * newSection, 344 * steps.length - stepsElement.clientWidth)
    
    stepsElement.style.marginLeft = -newScroll + 'px'

    for (let i = 0; i < steps.length; i++) {
      const child = steps[i] as HTMLElement

      if (i === newSection || (i > newSection && 344 * (i - newSection + 1) <= stepsElement.clientWidth)) {
        child.style.opacity = '1.0'
      } else {
        child.style.opacity = '0.6'
      }
    }

    if (newScroll >= stepsElement.scrollWidth - stepsElement.clientWidth) {
      setNextEnabled(false)
    } else {
      setNextEnabled(true)
    }

    if (newScroll <= 0) {
      setPrevEnabled(false)
    } else {
      setPrevEnabled(true)
    }

    setSection(newSection)
  }

  const prevSection = () => {
    if (prevEnabled) {
      updateSection(section - 1)
    }
  }

  const nextSection = () => {
    if (nextEnabled) {
      updateSection(section + 1)
    }
  }

  const onTouchStart = (evt: TouchEvent<HTMLElement>) => {
    const firstTouch = evt.touches[0]
    setXDown(firstTouch.clientX)
    setYDown(firstTouch.clientY)
  }

  const onTouchMove = (evt: TouchEvent<HTMLElement>) => {
    if (!xDown || !yDown) {
      return
    }

    var xUp = evt.touches[0].clientX                                
    var yUp = evt.touches[0].clientY

    var xDiff = xDown - xUp
    var yDiff = yDown - yUp
                                                                        
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        nextSection()
      } else {
        prevSection()
      }                       
    }

    setXDown(null)
    setYDown(null)
  }

  useEffect(() => {
    const element = document.getElementById('how-it-works')

    const onScroll = () => {
      const stepsElement = document.getElementById('steps-wrapper')

      if (element === undefined || element === null || stepsElement === undefined || stepsElement === null) {
        return
      }

      const maxSteps = 6 - Math.floor(stepsElement.clientWidth / 344) + 1

      if (window.scrollY - element.offsetTop >= 0 && window.scrollY - element.offsetTop <= 700 / maxSteps) {
        updateSection(0)
      } 

      if (window.scrollY - element.offsetTop > 700 / maxSteps && window.scrollY - element.offsetTop <= 2 * 700 / maxSteps) {
        updateSection(1)
      } 
      
      if (window.scrollY - element.offsetTop >= 2 * 700 / maxSteps && window.scrollY - element.offsetTop < 3 * 700 / maxSteps) {
        updateSection(2)
      }

      if (window.scrollY - element.offsetTop >= 3 * 700 / maxSteps && window.scrollY - element.offsetTop < 4 * 700 / maxSteps) {
        updateSection(3)
      }

      if (maxSteps > 4 && window.scrollY - element.offsetTop >= 4 * 700 / maxSteps && window.scrollY - element.offsetTop < 5 * 700 / maxSteps) {
        updateSection(4)
      }

      if (maxSteps > 5 && window.scrollY - element.offsetTop >= 5 * 700 / maxSteps && window.scrollY - element.offsetTop < 6 * 700 / maxSteps) {
        updateSection(5)
      }
    }

    const updateSticky = () => {
      if (element === undefined || element === null) {
        return
      }

      if (window.innerWidth < 992 || window.innerHeight < 600) {
        if (element.classList.contains('is-sticky')) {
          element.style.height = 'auto'
          element.classList.remove('is-sticky')
          document.removeEventListener('scroll', onScroll)
        }
      } else {
        if (!element.classList.contains('is-sticky')) {
          element.style.height = `${window.innerHeight + 700}px`
          element.classList.add('is-sticky')
          document.addEventListener('scroll', onScroll)
        }
      }

      updateSection(0)
    }

    updateSticky()

    const onResize = () => {
      updateSticky()
    }

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <div
      id="how-it-works"
      className="bg-white"
    >
      <div>
        <div className="overflow-hidden">
          <Container className="py-6">
            <Row>
              <Col
                xs={12}
                md={6}
                className="px-4 px-sm-3"
              >
                <h1 className="display-1 mb-4">
                  How it works
                </h1>
              </Col>

              <Col
                xs={12}
                md={6}
                className="px-4 px-sm-3"
              >
                <p className="text-muted mb-5">
                  We offer a complete solution to source, analyse and market natural capital investment opportunities.
                </p>

                <p>
                  <Link
                    to="/register"
                    className="btn btn-primary btn-lg rounded-pill"
                    style={{ 
                      paddingLeft: '25px',
                      paddingRight: '25px'
                    }}
                  >
                    Register your interest
                  </Link>
                </p>
              </Col>
            </Row>

            <Row
              id="steps-row"
              className="position-relative mt-5"
              onTouchStart={onTouchStart}
              onTouchMove={onTouchMove}
            >
              <div
                className="d-flex align-items-center px-0"
                id="steps-wrapper"
                style={{ 
                  transition: 'margin-left 0.2s' 
                }}
              >
                { [
                  { title: 'Searching', body: 'Using satellite technology, our proprietary algorithms and local partners, we pinpoint the best lands to invest in.' },
                  { title: 'Packaging', body: 'Working through a unique legal structure, we convert the lands into investible securities backed by EU regulation.' },
                  { title: 'Investing', body: 'Through our centralised platform, we give our investors a safe, seamless and transparent way to deploy their funds.' },
                  { title: 'Management', body: 'We work closely with our operators, managers and specialised partners to ensure an efficient running of the land.' },
                  { title: 'Returns', body: 'Our investors receive an annual yield on their investment coming from the rents paid by operators and any other revenue.' },
                  { title: 'Sale', body: 'After an average holding of 5-10 years and ensuring a long-lasting positive impact, we find a suitable buyer for the assets.' },
                ].map((step, index) => (
                  <div
                    className="how-it-works-step p-4 mx-3 flex-shrink-0"
                    key={index}
                    style={{
                      background: '#f7f7f7',
                      borderRadius: '15px',
                      width: '320px'
                    }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-center mb-4"
                      style={{
                        background: '#e8e8e8',
                        color: '#9a9a9a',
                        fontWeight: 600,
                        width: '40px',
                        height: '40px',
                        borderRadius: '20px',
                        fontSize: '18px',
                      }}
                    >
                      { index+1 }
                    </div>

                    <h3 style={{ fontWeight: 600 }}>
                      { step.title }
                    </h3>

                    <p
                      className="text-muted mb-0"
                      style={{ fontSize: '14px' }}
                    >
                      { step.body }
                    </p>
                  </div>
                )) }

                { prevEnabled && (
                  <div
                    className="arrow-circle bg-primary text-white rounded-circle align-items-center justify-content-center cursor-pointer p-0"
                    style={{
                      left: '15px',
                    }}
                    onClick={() => updateSection(section - 1)}
                  >
                    <ArrowLeft size="18px" />
                  </div>
                ) }

                { nextEnabled && (
                  <div
                    className="arrow-circle bg-primary text-white rounded-circle align-items-center justify-content-center cursor-pointer p-0"
                    style={{
                      right: '15px',
                    }}
                    onClick={() => updateSection(section + 1)}
                  >
                    <ArrowRight size="18px" />
                  </div>
                ) }
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  )
}
