import { Container } from 'react-bootstrap'

export default function Privacy() {
  return (
    <>
      <div className="pt-6" />

      <div className="pt-5 pb-4">
        <Container>
          <h2 className="text-center display-2 mb-4">
            Privacy Policy
          </h2>

          <h4 className="display-6 text-center text-muted font-weight-normal mb-5">
            Last Updated: 16th May 2022
          </h4>

          <div className="fs-3 font-weight-light">
            <p>
              At Bageera, we take privacy and security seriously. This Privacy Policy outlines how Bageera Ltd. and its affiliates (collectively, “Bageera,” “we,” “our,” or “us”) process the information we collect (“Personal Data”) about you (“Users”) through our website and other online services (collectively, the “Services”) and when you otherwise interact with us, such as through phone or email channels.
            </p>

            <h5 className="display-5 my-4">
              Data Controller and Owner
            </h5>

            <p>
              <b>Name:</b> Bageera Ltd<br />
              <b>Address:</b> 125 Kingsway, WC2B 6NH, London<br />
              <b>E-mail:</b> <a href="mailto:hello@bageera.co" className="text-info">hello@bageera.co</a>
            </p>

            <h5 className="display-5 my-4">
              Types of Data Collected
            </h5>

            <p>
              Among the types of Personal Data that this website collects, by itself or through third parties, there are:
            </p>
            
            <ul role="list">
              <li className="mb-4">
                <strong>Identity Data</strong>, such as your name and other basic data on government-issued identification documents;
              </li>

              <li className="mb-4">
                <strong>Contact Data</strong>, such as your email;
              </li>

              <li className="mb-4">
                <strong>Profile Data</strong>, such as your interests, feedback and survey responses; and
              </li>
              
              <li className="mb-4">
                <strong>Usage Information, </strong>such as your activity on our Services, including device identifiers, time and date of access.
              </li>
            </ul>
            
            <p>
              The Personal Data may be freely provided by the User, or collected automatically when using this website.
            </p>
            
            <p>
              Any use of Cookies– or of other tracking tools – by this website or by the owners of third-party services used by this website, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User.
            </p>
          
            <p>
              Failure to provide certain Personal Data may make it impossible for this website to provide its services.
            </p>
            
            <p>
              The User assumes responsibility for the Personal Data of third parties published or shared through this website and declared to have the right to communicate or broadcast them, thus relieving the Data Controller of all responsibility.
            </p>
            
            <h5 className="display-5 my-4">
              Obtention and Purpose of the Data
            </h5>
            
            <p>
              Most of the personal information we process is provided to us directly by you for one of the following reasons:
            </p>
            
            <ul>
              <li className="mb-4">
                Analytics, to personalise and enhance our services and product offering; and
              </li>
              
              <li className="mb-4">
                Contacting the User to communicate you about offers and other things you would be interested in (such as surveys, updates and further information).
              </li>
            </ul>
            
            <p>
              Under the General Data Protection Regulation (GDPR), the lawful bases we rely on for processing this information are:
            </p>
            
            <ul>
              <li className="mb-4">
                Your consent. You are able to remove your consent at any time. You can do this by contacting the Data Controller (please refer to the contact section below).
              </li>
              
              <li className="mb-4">
                We have a legitimate interest. The processing is necessary for your legitimate interest to contact you back, unless there is a good reason to protect the User’s personal data which overrides those legitimate interest.
              </li>
            </ul>
            
            <h5 className="display-5 my-4">
              Mode and Place of Processing the Data
            </h5>
            
            <p>
              The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorised access, disclosure, modification, or unauthorised destruction of the Data.
            </p>
            
            <p>
              The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site– such as administration, sales, marketing, legal, etc. – or external parties –such as technical service providers, hosting providers, IT companies, communication agencies, etc. – appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.
            </p>
            
            <p>
              The Data is processed at the Data Controller’s operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.
            </p>

            <p>
              The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data.
            </p>
            
            <h5 className="display-5 my-4">
              Your Data Protection Rights
            </h5>
            
            <p>
              Under data protection law, you have rights including:
            </p>
            
            <ul>
              <li className="mb-4">
                <strong>Your right of access</strong> - You have the right to ask us for copies of your personal information.
              </li>
              
              <li className="mb-4">
                <strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.
              </li>
              
              <li className="mb-4">
                <strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances.
              </li>
              
              <li className="mb-4">
                <strong>Your right to restriction of processing - </strong>You have the right to ask us to restrict the processing of your personal information in certain circumstances.
              </li>
              
              <li className="mb-4">
                <strong>Your right to object to processing - </strong>You have the the right to object to the processing of your personal information in certain circumstances.
              </li>
              
              <li className="mb-4">
                <strong>Your right to data portability - </strong>You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.
              </li>
              
              <li className="mb-4">
                You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you.
              </li>
            </ul>
            
            <h5 className="display-5 my-4">
              Others
            </h5>
            
            <ul role="list">
              <li>
                <strong>Children: </strong>We do not knowingly collect or solicit any information from anyone under the age of 18 on these Services. In the event we learn that we have inadvertently collected personal information from a child under age 18, we will take reasonable steps to delete that information.
              </li>
              
              <li>
                <strong>Changes to this policy: </strong>The Privacy Policy will evolve with time, and we will revise the effective date when we post any update, providing additional notice in some cases.
              </li>
              
              <li>
                <strong>Contact: </strong>please contact us at <a href="mailto:hello@bageera.co" className="text-info">hello@bageera.co</a> if you wish to make a request.
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </>
  )
}
