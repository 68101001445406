import { Col, Container, Row } from 'react-bootstrap'

export default function Cookies() {
  return (
    <>
      <div className="pt-6" />

      <div className="pt-5 pb-4">
        <Container>
          <h2 className="text-center display-2 mb-4">
            Cookies Policy
          </h2>

          <h4 className="display-6 text-center text-muted font-weight-normal mb-5">
            Last Updated: 16th May 2022
          </h4>

          <div className="fs-3 font-weight-light">
            <h5 className="display-5 mb-4">
              Introduction
            </h5>

            <p>
              At Bageera, we take privacy and security seriously. This Cookies Policy outlines how Bageera Ltd. and its affiliates (collectively, “Bageera,” “we,” “our,” or “us”) use cookies on our website and other online services (collectively, the “Services”).
            </p>

            <p>
              By using the Services, you consent to the use of essential cookies that allow you to browse the website and use the different options and services that it provides. You can also opt-in to the use of non-essential cookies for analytics, marketing and user experience purposes.  
            </p>

            <p>
              Our Cookies Policy explains what cookies are, how we use cookies, your choices regarding cookies and further information about cookies.
            </p>

            <h5 className="mt-5 display-5 mb-4">
              What are Cookies
            </h5>

            <p>
              Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third­ party to recognise you and make your next visit easier and the Service more useful to you.
            </p>
            
            <p>
              Cookies can be "persistent" or "session" cookies.
            </p>

            <h5 className="mt-5 display-5 mb-4">
              How Bageera uses Cookies
            </h5>

            <p>
              When you use and access the Services, we may place a number of cookies files in your web browser.
            </p>

            <p>
              We use both session and persistent cookies on the Service and we use different types of cookies with different purposes: ­
            </p>

            <ul>
              <li className="mb-3">
                <b>Essential cookies</b> - We may use essential cookies that allow you to browse the website and use the different options and services that it provides, such as controlling data traffic and communication, authenticating users or preventing fraudulent use.
              </li>

              <li>
                <b>Analytics cookies</b> - We use this type of cookies to collect statistical information about visitors of our websites and the pages they view. Information collected by these cookies is aggregated and used anonymously. Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.
              </li>
            </ul>

            <h5 className="mt-5 display-5 mb-4">
              What Cookies is Bageera using
            </h5>

            <Row className="border-top py-3 mx-0 font-weight-bold">
              <Col xs={2}>
                Cookie Name
              </Col>

              <Col xs={2}>
                Provider
              </Col>

              <Col xs={4}>
                Purpose
              </Col>

              <Col xs={2}>
                Expiry
              </Col>

              <Col xs={2}>
                Type
              </Col>
            </Row>

            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                axeptio_all_vendors
              </Col>
              <Col xs={2}>
                bageera.co
              </Col>
              <Col xs={4}>
                Used to manage non-essential cookie consent.
              </Col>
              <Col xs={2}>
                1 Year
              </Col>
              <Col xs={2}>
                HTTP Cookie (essential)
              </Col>
            </Row>

            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                axeptio_auth-<br />orized_vendors
              </Col>
              <Col xs={2}>
                bageera.co
              </Col>
              <Col xs={4}>
                Used to manage non-essential cookie consent.
              </Col>
              <Col xs={2}>
                1 Year
              </Col>
              <Col xs={2}>
                HTTP Cookie (essential)
              </Col>
            </Row>
            
            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                axeptio_cookies
              </Col>
              <Col xs={2}>
                bageera.co
              </Col>
              <Col xs={4}>
                Used to manage non-essential cookie consent.
              </Col>
              <Col xs={2}>
                1 Year
              </Col>
              <Col xs={2}>
                HTTP Cookie (essential)
              </Col>
            </Row>
            
            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                __cf_bm
              </Col>
              <Col xs={2}>
                typeform.com
              </Col>
              <Col xs={4}>
                Used to distinguish between humans and bots.
              </Col>
              <Col xs={2}>
                30 Minutes
              </Col>
              <Col xs={2}>
                HTTP Cookie (essential)
              </Col>
            </Row>
            
            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                attribution_ user_id
              </Col>
              <Col xs={2}>
                typeform.com
              </Col>
              <Col xs={4}>
                Used in context with the website’s pop-up questionnaires and messengering
              </Col>
              <Col xs={2}>
                1 Year
              </Col>
              <Col xs={2}>
                HTTP Cookie (essential)
              </Col>
            </Row>
            
            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                __ga
              </Col>
              <Col xs={2}>
                bageera.co
              </Col>
              <Col xs={4}>
                Used to distinguish users.
              </Col>
              <Col xs={2}>
                2 Years
              </Col>
              <Col xs={2}>
                HTTP Cookie (analytics)
              </Col>
            </Row>
            
            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                __gid
              </Col>
              <Col xs={2}>
                bageera.co
              </Col>
              <Col xs={4}>
                Used to distinguish users
              </Col>
              <Col xs={2}>
                24 Hours
              </Col>
              <Col xs={2}>
                HTTP Cookie (analytics)
              </Col>
            </Row>
            
            <Row className="border-top py-3 mx-0">
              <Col xs={2}>
                __gat
              </Col>
              <Col xs={2}>
                bageera.co
              </Col>
              <Col xs={4}>
                Used  to throttle request rate.
              </Col>
              <Col xs={2}>
                1 Minutes
              </Col>
              <Col xs={2}>
                HTTP Cookie (analytics)
              </Col>
            </Row>
            
            <Row className="border-top border-bottom py-3 mx-0 mb-4">
              <Col xs={2}>
                __gac_
              </Col>
              <Col xs={2}>
                bageera.co
              </Col>
              <Col xs={4}>
                Contains campaign related information for the user.
              </Col>
              <Col xs={2}>
                90 Days
              </Col>
              <Col xs={2}>
                HTTP Cookie (analytics)
              </Col>
            </Row>

            <p>
              The Services also use Google Analytics for the collection of statistical information and its subsequent analysis. Google Analytics is a web analytics service provided by Google, Inc., a Delaware company with its main office at 1600 Amphitheater Parkway, Mountain View (California), CA 94043, United States (“Google”).
            </p>

            <p>
              The cookies used by Google Analytics are only associated with an anonymous user and the anonymized IP of your computer and, therefore, do not provide your personal data. The information generated by the cookie about the web use will be directly transmitted and filed by Google on its servers in the United States. Google will use this information on their own, in order to keep track of your use of the Web, compiling activity reports and providing other services related to Web activity and Internet use.
            </p>

            <p>
              Google may transmit this information to third parties when required by law, or when these third parties process the information on Google's behalf. 
            </p>

            <h5 className="mt-5 display-5 mb-4">
              User Choices
            </h5>

            <p>
              If you would like to delete cookies or instruct your web browser to delete or refuse the essential cookies we are using, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.
            </p>

            <p>
              For other non-essential cookies, you may use the cookie consent tool that appears the first time you visit our website to enable or disable the use of each individual cookie or of all non-essential cookies in group. By default, non-essential cookies will not be used until you explicitly give your consent to do so.
            </p>

            <p>
              If you change your mind about the non-essential cookies being used after using our cookie consent tool, you can re-open it by clicking <a href="javascript:openAxeptioCookies()" className="text-info">here</a>.
            </p>

            <h5 className="mt-5 display-5 mb-4">
              Changes of Terms
            </h5>

            <p>
              We reserve the right to make changes to these terms as the Cookies Policy might evolve with time. We will revise the effective date when we post any update, providing additional notice when we make material changes as appropriate under the circumstances. Therefore, please make sure you read any such notice carefully. If you do not wish to continue using the Service under the new version of the Cookies Policy, you may terminate your registration by contacting us at the contact information provided below.
            </p>

            <h5 className="mt-5 display-5 mb-4">
              Additional Information
            </h5>

            <p>
              You can learn more about cookies and the following third party websites:
            </p>

            <ul>
              <li className="mb-3">
                <b>All About Cookies:</b> <a href="http://www.allaboutcookies.org/" className="text-info">http://www.allaboutcookies.org/</a>
              </li>

              <li>
                <b>Network Advertising Initiative:</b> <a href="http://www.networkadvertising.org/" className="text-info">http://www.networkadvertising.org/</a>
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </>
  )
}
