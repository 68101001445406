import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export default function WhyInvest() {
  const [section, setSection] = useState<number>(1)

  useEffect(() => {
    const element = document.getElementById('why-invest')

    const onScroll = () => {
      if (element === undefined || element === null) {
        return
      }
      
      if (window.scrollY >= element.offsetTop + 2 * (element.clientHeight - window.innerHeight) / 3) {
        setSection(3)
        return
      }
  
      if (window.scrollY >= element.offsetTop + (element.clientHeight - window.innerHeight) / 3) {
        setSection(2)
        return
      }
  
      setSection(1)
    }

    const updateSticky = () => {
      if (element === undefined || element === null) {
        return
      }

      if (window.innerWidth < 992 || window.innerHeight < 780) {
        if (element.classList.contains('is-sticky')) {
          element.style.height = 'auto'
          element.classList.remove('is-sticky')
          document.removeEventListener('scroll', onScroll)
        }
      } else {
        if (!element.classList.contains('is-sticky')) {
          element.style.height = `${window.innerHeight + 900}px`
          element.classList.add('is-sticky')
          document.addEventListener('scroll', onScroll)
        }
      }
    }

    updateSticky()
    window.addEventListener('resize', updateSticky)

    return () => {
      document.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', updateSticky)
    }
  }, [])

  return (
    <div
      className="bg-white position-relative"
      id="why-invest"
    >
      <Container className="d-flex align-items-center py-6">
        <div>
          <h1 className="display-1 mb-5 px-3 px-sm-0">
            Why invest <span className="text-muted">through Bageera?</span>
          </h1>

          <Row className="align-items-center">
            <Col
              xs={12}
              md={6}
              className="d-none d-lg-block pe-5"
            >
              <div
                className={classNames(
                  'ps-4 pe-5 mb-5',
                  'cursor-pointer',
                  'border-1 border-start',
                  section === 1 ? 'border-primary' : 'border-white',
                )}
                style={section !== 1 ? { opacity: 0.4, transition: 'opacity 0.5s' } : { transition: 'opacity 0.5s' }}
                onClick={() => {
                  const element = document.getElementById('why-invest')
  
                  if (element !== undefined && element !== null && element.classList.contains('is-sticky')) {
                    window.scrollTo(0, element.offsetTop)
                  } else {
                    setSection(1)
                  }
                }}
              >
                <h4 className="display-5 mt-0 mb-3 pb-1">
                  Impact investing is still investing
                </h4>

                <p className="text-muted fs-3 font-weight-light">
                  We solve our investors' needs to make a positive impact, while generating financial returns
                </p>
              </div>

              <div
                className={classNames(
                  'ps-4 pe-5 mb-5',
                  'cursor-pointer',
                  'border-1 border-start',
                  section === 2 ? 'border-primary' : 'border-white',
                )}
                style={section !== 2 ? { opacity: 0.4, transition: 'opacity 0.5s' } : { transition: 'opacity 0.5s' }}
                onClick={() => {
                  const element = document.getElementById('why-invest')
  
                  if (element !== undefined && element !== null && element.classList.contains('is-sticky')) {
                    window.scrollTo(0, element.offsetTop + (element.clientHeight - window.innerHeight) / 3)
                  } else {
                    setSection(2)
                  }
                }}
              >
                <h4 className="display-5 mt-0 mb-3 pb-1">
                  Nature's processes are slow, but investing in it doesn't have to be
                </h4>

                <p className="text-muted fs-3 font-weight-light">
                  We create a liquid asset class that adapts to realistic investment horizons
                </p>
              </div>

              <div
                className={classNames(
                  'ps-4 pe-5 mb-4',
                  'cursor-pointer',
                  'border-1 border-start',
                  section === 3 ? 'border-primary' : 'border-white',
                )}
                style={section !== 3 ? { opacity: 0.4, transition: 'opacity 0.5s' } : { transition: 'opacity 0.5s' }}
                onClick={() => {
                  const element = document.getElementById('why-invest')
  
                  if (element !== undefined && element !== null && element.classList.contains('is-sticky')) {
                    window.scrollTo(0, element.offsetTop + 2 * (element.clientHeight - window.innerHeight) / 3)
                  } else {
                    setSection(3)
                  }
                }}
              >
                <h4 className="display-5 mt-0 mb-3 pb-1">
                  Investing is a serious thing, and it has to be backed by reliable information
                </h4>

                <p className="text-muted fs-3 font-weight-light">
                  We use a proprietary database and analysis tool to identify attractive investment opportunities by valuing their financial, environmental and social returns
                </p>
              </div>
            </Col>

            <Col
              xs={12}
              lg={6}
              className="px-0 px-sm-3 pe-lg-5"
            >
              <div className="d-block d-lg-none px-4 px-sm-0 py-4">
                <h4 className="display-5 mt-0 mb-3 pb-1">
                  Impact investing is still investing
                </h4>

                <p className="text-muted fs-3 font-weight-light">
                  We solve our investors' needs to make a positive impact, while generating financial returns
                </p>
              </div>

              <img
                src="/images/home/whyinvest1.jpg"
                srcSet="/images/home/whyinvest1@2x.jpg 2x"
                alt=""
                className={classNames(
                  'color-card',
                  'mw-100',
                  'rounded-3 rounded-xs-0',
                  section !== 1 && 'd-block d-lg-none'
                )}
              />

              <div className="d-block d-lg-none px-4 px-sm-0 pt-6 pb-4">
                <h4 className="display-5 mt-0 mb-3 pb-1">
                  Nature's processes are slow, but investing in it doesn't have to be
                </h4>

                <p className="text-muted fs-3 font-weight-light">
                  We create a liquid asset class that adapts to realistic investment horizons
                </p>
              </div>

              <img
                src="/images/home/whyinvest2.png"
                srcSet="/images/home/whyinvest2@2x.png 2x"
                alt=""
                className={classNames(
                  'color-card',
                  'mw-100',
                  'rounded-3 rounded-xs-0',
                  section !== 2 && 'd-block d-lg-none'
                )}
              />

              <div className="d-block d-lg-none px-4 px-sm-0 pt-6 pb-4">
                <h4 className="display-5 mt-0 mb-3 pb-1">
                  Investing is a serious thing, and it has to be backed by reliable information
                </h4>

                <p className="text-muted fs-3 font-weight-light">
                  We use a proprietary database and analysis tool to identify attractive investment opportunities by valuing their financial, environmental and social returns
                </p>
              </div>

              <img
                src="/images/home/whyinvest3.png"
                srcSet="/images/home/whyinvest3@2x.png 2x"
                alt=""
                className={classNames(
                  'color-card',
                  'mw-100',
                  'rounded-3 rounded-xs-0',
                  section !== 3 && 'd-block d-lg-none'
                )}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
