import { KeyboardEvent, ReactNode } from 'react'
import { Col } from 'react-bootstrap'

import LoadingButton from './LoadingButton'

interface StepInputProps {
  step?: number,
  title: string,
  description?: string,
  textNext?: string,
  onBack: () => void,
  onNext: () => void,
  isSubmitting?: boolean,
  children?: ReactNode
}

export default function StepInput({ step, title, description, textNext, onBack, onNext, isSubmitting = false, children }: StepInputProps) {
  return (
    <>
      <Col
        xs="auto"
        className="text-info mt-1 pt-1 me-3 fs-4"
      >
        { step })
      </Col>

      <Col
        xs={true}
        md={10}
        lg={8}
        xl={7}
      >
        <p className="fs-2 font-weight-lighter mb-2">
          { title }
        </p>

        { description && (
          <p className="lead fs-4 text-secondary pt-2 mb-4">
            { description }
          </p>
        ) }

        <div
          className="mt-4 mb-5"
          onKeyDown={(keyEvent: KeyboardEvent) => {
            if (keyEvent.key === 'Enter') {
              onNext()
            }
          }}
        >
          { children }
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-sm btn-outline-secondary px-3"
            onClick={e => onBack()}
          >
            Back
          </button>

          <div>
            <span className="me-3 text-muted d-none d-sm-inline">
              press <b>Enter ↵</b> or 
            </span>

            <LoadingButton
              busy={isSubmitting}
              type="button"
              variant="info"
              className="px-4"
              onClick={e => onNext()}
            >
              { textNext ?? 'Next' }
            </LoadingButton>
          </div>
        </div>
      </Col>
    </>
  )
}
