import { ForwardedRef, forwardRef } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import type { ButtonProps } from 'react-bootstrap'

interface LoadingButtonProps extends ButtonProps {
  busy: boolean,
  loadingText?: string,
  spinner?: boolean
}

const LoadingButton = forwardRef(({ children, busy, loadingText = 'Loading...', spinner = true, ...other } : LoadingButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
  return (
    <Button 
      ref={ref}
      disabled={busy}
      {...other}
    >
      {busy ? (
        spinner ? (
          <>
            <Spinner
              animation="border"
              size="sm"
            />

            {' '}&nbsp;

            {loadingText}
          </>
        ) : loadingText
      ) : children}
    </Button>
  )
})

export default LoadingButton
