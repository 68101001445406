import { Col, Container, Row } from 'react-bootstrap'

import Careers from '../components/home/Careers'

export default function About() {
  return (
    <>
      <div className="pt-6" />

      <div className="py-6">
        <Container className="d-flex flex-column flex-auto">
          <Row>
            <Col
              xs={12}
              lg={5}
              className="px-4 px-sm-3"
            >
              <h2 className="display-2 mb-4">
                Our purpose
              </h2>
            </Col>

            <Col
              xs={12}
              lg={7}
              className="px-4 px-sm-3"
            >
              <h2 className="display-3 font-weight-light mb-5">
                We transform nature into an investible asset class.
              </h2>

              <p
                className="lead mb-5"
                style={{ lineHeight: '1.8' }}
              >
                Helping the world invest in <span className="text-success">natural capital</span>,
                
                <br />

                which values nature for all the <span className="text-success">benefits</span> it provides us.
              </p>
              
              <p
                className="text-secondary mb-0"
                style={{ lineHeight: '2.0' }}
              >
                <span className="text-success">Natural capital:</span> agricultural land, forests, plains, wetlands, etc.

                <br />

                <span className="text-success">Benefits:</span> carbon capture, soil fertility, water purification, wildlife conservation, and more.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-white py-6">
        <Container className="d-flex flex-column flex-auto">
          <Row>
            <Col
              xs={12}
              lg={5}
              className="px-4 px-sm-3"
            >
              <h2 className="display-2 mb-4">
                Our vision
              </h2>
            </Col>

            <Col
              xs={12}
              lg={7}
              className="px-4 px-sm-3"
            >
              <h2 className="display-3 font-weight-light mb-5 pb-4">
                Developing a <span className="font-weight-normal">complete solution to source, analyse and market</span> natural capital investment opportunities.
              </h2>

              <div className="lead d-flex align-items-center mb-3">
                <span
                  className="d-flex align-items-center justify-content-center bg-success text-white rounded-circle fs-4"
                  style={{ width: '24px', height: '24px', marginRight: '14px' }}
                >
                  1
                </span>

                <span>Natural capital <span className="font-weight-bold">search engine</span></span>
              </div>

              <p
                className="font-weight-light mb-5"
                style={{ lineHeight: '2.0', marginLeft: '38px' }}
              >
                Proprietary database and analysis tool based on predictive machine learning models to identify attractive investment opportunities by valuing their financial, environmental and social returns.
              </p>
              
              <div className="lead d-flex align-items-center mb-3">
                <span
                  className="d-flex align-items-center justify-content-center bg-success text-white rounded-circle fs-4"
                  style={{ width: '24px', height: '24px', marginRight: '14px' }}
                >
                  2
                </span>

                <span>Natural capital <span className="font-weight-bold">investment platform</span></span>
              </div>

              <p
                className="font-weight-light mb-0"
                style={{ lineHeight: '2.0', marginLeft: '38px' }}
              >
                Complete infrastructure to transform natural capital assets into investible opportunities for our institutional clients.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Careers />
    </>
  )
}
