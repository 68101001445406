import { Col, Container, Row } from 'react-bootstrap'
import { ArrowRight } from 'react-feather'

export default function Careers() {
  return (
    <div
      id="careers"
      className="bg-white"
    >
      <Container className="py-6">
        <Row>
          <Col
            xs={12}
            md={7}
            className="d-flex flex-column px-0 px-sm-3 pe-md-4 mb-1 mb-sm-3 mb-md-0"
          >
            <div className="rounded-3 rounded-xs-0 px-4 py-5 p-sm-5 d-flex flex-column justify-content-between bg-primary flex-auto text-white">
              <div className="pb-3 p-sm-3">
                <h3 className="display-3 mb-5">
                  Want to make a real difference?
                </h3>

                <p className="lead mb-4">
                  This is your chance to work in a company that will enable the transition towards a sustainable economy by challenging the status quo and redifining the value of nature.
                </p>

                <p className="lead">
                  If you're ready to give 100% in helping us achieve our mission we'd love to get to know more about you.
                </p>
              </div>

              <div className="d-flex p-sm-3">
                <a
                  href="https://bageera.notion.site/Careers-at-Bageera-546f2c24f1d544bc849e738650ea8cb5"
                  className="btn btn-lg btn-white px-4 rounded-pill d-flex align-items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  Join the team 
                  
                  <ArrowRight
                    size="16px"
                    className="ms-2"
                  />
                </a>
              </div>
            </div>
          </Col>

          <Col
            xs={12}
            md={5}
            className="px-0 px-sm-3 ps-md-0 d-flex"
          >
            <div
              className="bg-cover rounded-3 rounded-xs-0 flex-auto"
              style={{
                backgroundImage: 'url(images/about/careers@2x.jpg)',
                minHeight: '300px',
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}
