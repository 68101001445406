import { Container } from 'react-bootstrap'

export default function Terms() {
  return (
    <>
      <div className="pt-6" />

      <div className="pt-5 pb-4">
        <Container>
          <h2 className="text-center display-2 mb-4">
            Terms &amp; Conditions
          </h2>

          <h4 className="display-6 text-center text-muted font-weight-normal mb-5">
            Last Updated: 16th May 2022
          </h4>

          <div className="fs-3 font-weight-light">
            <h5 className="display-5 mb-4">
              Introduction
            </h5>

            <p>
              These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you") and Bageera Ltd. ("Bageera", "we", "us", or "our"), concerning your access to and use of our website as well as any other media form, media channel, mobile website or related link(collectively, the "Site"). The Site includes, but not limited to, information on Bageera’s offering, an early-access register for future natural capital investment opportunities and the ability to join our community (collectively, the "Services"). You agree that by accessing the Site and/or the Services, you have read, understood, and agree to be bound by all of these Terms and Conditions described herein.
            </p>

            <h5 className="display-5 my-4">
              Acceptance of Terms and Conditions
            </h5>
            
            <p>
              In order to use the Services, you must (i) be 18 years or older; (ii) have the power to enter into a binding agreement with us through your acceptance of these terms;(iii) submit information that is true, accurate, complete and current at the time you access and use the Services; (iv) you will not access the Site or use the Services through automated or non-human means; and (v) you will not access the Site our use the Services for illegal or unauthorised purposes.
            </p>

            <p>
              By accessing the Site and using the Service, you agree to follow and be bound by these Terms and Conditions, including the policies referenced herein.
            </p>

            <p>
              If you do not agree with all of these terms of use, then you are expressly prohibited from using the site and/or the services and you must discontinue use immediately.
            </p>

            <h5 className="display-5 my-4">
              Intellectual Property Rights
            </h5>

            <p>
              The Site and the Services are our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks")are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws.
            </p>

            <h5 className="display-5 my-4">
              Users
            </h5>

            <p>
              The Content of the Services are intended for European residents only.
            </p>

            <h5 className="display-5 my-4">
              User Registration
            </h5>

            <p>
              You are required to register with the Site in order to gain early-access to the Services. At the time of registration interest, you will provide us with your name and email address, and possibly with other information requested at the time of registration.
            </p>

            <h5 className="display-5 my-4">
              Feedback
            </h5>

            <p>
              Any feedback, questions, comments, suggestions, ideas or other information related to our Site our use of our Services that you might provide are non-confidential and shall become part of our sole property. We shall own exclusive rights and shall be entitled to the unrestricted use and dissemination of such feedback for any lawful and/or commercial purpose, without acknowledgment or compensation to you. You acknowledge and agree there shall be no recourse against us for any alleged or actual infringement or misappropriation of any proprietary right in your feedback.
            </p>

            <h5 className="display-5 my-4">
              Termination and Change of Terms
            </h5>

            <p>
              These Terms and Conditions shall remain in full force and effect while you access our Site and use our Services, until terminated by Bageera or you.
            </p>
            
            <p>
              You agree that, without notice, Bageera may terminate these Terms and Conditions, or suspend your access to the Service and Content, with or without cause at anytime and effective immediately. Bageera shall not be liable to you or any third party for such termination or suspension.
            </p>

            <p>
              We reserve the right to make changes as these Terms and Conditions might evolve with time. We will revise the effective date when we post any update, providing additional notice when we make material changes as appropriate under the circumstances. Therefore, please make sure you read any such notice carefully. If you do not wish to continue using the Service under the new version of the Terms and Conditions, you may terminate your registration by contacting us at the contact information provided below.
            </p>

            <h5 className="display-5 my-4">
              Disclaimer and Limitations of Liability
            </h5>

            <p>
              The Content and the Service are provided on an “as is” and “as available” basis. To the fullest extent permitted under applicable law, Bageera and any Third-Party Providers expressly disclaim all warranties of any kind with respect to the Content and the Service, whether express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Bageera nor Third-Party Providers guarantee the accuracy, timeliness, completeness or usefulness of any Content. You agree to use the Content and the Service only at your own risk.
            </p>

            <p>
              Neither Bageera nor the Third-Party Providers explicitly or implicitly endorse or approve any Third-Party Content. Third-Party Content is provided for informational purposes only.
            </p>

            <p>
              The Content is not intended to provide financial, legal, tax or investment advice or recommendations. You are solely responsible for determining whether any self-directed investment, investment strategy or related transaction is appropriate for you based on your personal investment objectives, financial circumstances and risk tolerance. You should consult your legal or tax professional regarding your specific situation.
            </p>

            <p>
              Bageera and any third-party providers will not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including, but not limited to, damages for loss of profits, revenue, income, goodwill, use, data or other intangible losses.
            </p>

            <h5 className="display-5 my-4">
              Applicable Policies
            </h5>
            
            <p>
              Your access and use of the Site are not only subject to these Terms and Conditions, but also to Bageera's policies relating to the Content and Services, including, but without limitation, the Privacy Policy and Cookies Policy available on the Site. You agree to be bound by these policies.
            </p>

            <h5 className="display-5 my-4">
              Applicable Law & Servability
            </h5>

            <p>
              These terms shall be governed by the laws of the United Kingdom.
            </p>

            <p>
              If any provision of these Terms and Conditions is deemed unlawful, void or for any reason unenforceable, then that provision will be deemed severable from these Terms and Conditions and will not affect the validity and enforceability of the remaining provisions.
            </p>

            <h5 className="display-5 my-4">
              Contact Us
            </h5>

            <p>
              In order to resolve a complaint or request further information about the use of the Site and Services, please contact us at:
            </p>

            <p>
              <b>Bageera Ltd.</b><br/>
              125 Kingsway – Aviation WeWork c/o Bageera<br/>
              WC2B 6NH, London, United Kingdom<br/>
              Email: <a href="mailto:hello@bageera.co" className="text-info">hello@bageera.co</a>
            </p>
          </div>
        </Container>
      </div>
    </>
  )
}
