import { Col, Container, Row } from 'react-bootstrap'
import { Check, X } from 'react-feather'

export default function WhyAgriculture() {
  return (
    <div className="bg-white py-5 pt-md-6">
      <Container>
        <h1 className="display-1 px-3 ps-sm-0">
          Investing in agricultural land <span style={{ color: '#6C757D' }}>means doing well by doing good.</span>
        </h1>

        <Row className="mt-5 pt-3 mb-6">
          <Col
            xs={12}
            md={9}
            lg={8}
            xl={7}
          >
            <h3 className="display-4 px-3 ps-sm-0">
              Sustainable agriculture offers the unique combination of strong financials...
            </h3>
          </Col>
        </Row>

        <Row>
          <div className="asset-table position-relative px-0 px-sm-3 mb-3">
            <div className="fixed-col bg-white me-1 me-lg-0">
              <div
                className="w-100 bg-primary text-white px-3 px-sm-4 py-2 rounded-3 rounded-end-0 rounded-xs-0"
                style={{ lineHeight: '82px' }}
              >
                Asset Class
              </div>

              {
                [
                  'Attractive Yield',
                  'Low Volatility',
                  'Equity Build Up',
                  'Unlevered',
                  'Asset-backed',
                  'Inflation Hedge',
                ].map((title: string, index: number) => (
                  <div
                    key={index}
                    className="w-100 bg-lighter text-secondary px-3 px-sm-4 py-3 mt-2 rounded-3 rounded-end-0 rounded-xs-0"
                    style={{ lineHeight: '30px' }}
                  >
                    { title }
                  </div>
                ))
              }
              
              <div
                className="w-100 px-3 px-sm-4 py-3 rounded-3 rounded-end-0 rounded-xs-0 mt-2"
                style={{ background: '#e4eaf0', lineHeight: '25px' }}
              >
                Annual Average Return *
              </div>
            </div>

            <div
              className="px-0"
              style={{ overflowY: 'scroll' }}
            >
              <div
                className="px-0"
                style={{ minWidth: '670px' }}
              >
                <div
                  className="w-100 bg-primary text-white px-4 py-2 rounded-3 rounded-start-0 rounded-xs-0"
                  style={{ lineHeight: '20px' }}
                >
                  <Row className="align-items-center">
                    <Col className="text-center">
                      <img
                        src="/images/home/table1.png"
                        srcSet="/images/home/table1@2x.png 2x, /images/home/table1@3x.png 3x"
                        alt="Agri-land"
                        style={{ height: '40px', marginBottom: '5px' }}
                      /><br />
                      
                      Agri-land
                    </Col>

                    <Col className="text-center">
                      <img
                        src="/images/home/table2.png"
                        srcSet="/images/home/table2@2x.png 2x, /images/home/table2@3x.png 3x"
                        alt="Stocks"
                        style={{ height: '40px', marginBottom: '5px' }}
                      /><br />

                      Stocks
                    </Col>

                    <Col className="text-center">
                      <img
                        src="/images/home/table3.png"
                        srcSet="/images/home/table3@2x.png 2x, /images/home/table3@3x.png 3x"
                        alt="Commercial Real Estate"
                        style={{ height: '40px', marginBottom: '2px' }}
                      /><br />

                      Commercial<br />Real Estate
                    </Col>

                    <Col className="text-center">
                      <img
                        src="/images/home/table4.png"
                        srcSet="/images/home/table4@2x.png 2x, /images/home/table4@3x.png 3x"
                        alt="Bonds"
                        style={{ height: '40px', marginBottom: '5px' }}
                      /><br />
                      
                      Bonds
                    </Col>

                    <Col className="text-center">
                      <img
                        src="/images/home/table5.png"
                        srcSet="/images/home/table5@2x.png 2x, /images/home/table5@3x.png 3x"
                        alt="Gold"
                        style={{ height: '40px', marginBottom: '5px' }}
                      /><br />

                      Gold
                    </Col>
                  </Row>
                </div>

                {
                  [
                    { values: [true, '-', true, true, false] },
                    { values: [true, false, false, true, false] },
                    { values: [true, true, true, false, false] },
                    { values: [true, false, false, true, true] },
                    { values: [true, false, true, false, true] },
                    { values: [true, false, true, false, true] }
                  ].map((row: { values: (string|boolean)[] }, index: number) => (
                    <div
                      key={index}
                      className="bg-lighter text-secondary px-4 py-3 mt-2 rounded-3 rounded-start-0 rounded-xs-0"
                      style={{ lineHeight: '30px' }}
                    >
                      <Row>
                        { row.values.map((value, index) => (
                          <Col
                            key={index}
                            className="text-center"
                          >
                            { value === true && (
                              <div 
                                className="mx-auto d-flex align-items-center justify-content-center bg-success-soft text-success rounded-circle"
                                style={{ width: '30px', height: '30px' }}
                              >
                                <Check size="16px" />
                              </div>
                            ) }

                            { value === false && (
                              <div 
                                className="mx-auto d-flex align-items-center justify-content-center bg-danger-soft text-danger rounded-circle"
                                style={{ width: '30px', height: '30px' }}
                              >
                                <X size="16px" />
                              </div>
                            ) }

                            { (value !== true && value !== false) && value }
                          </Col>
                        )) }
                      </Row>
                    </div>
                  )) 
                }

                <div
                  className="text-secondary px-4 py-3 mt-2 fs-3 rounded-3 rounded-start-0 rounded-xs-0"
                  style={{ background: '#e4eaf0', lineHeight: '50px' }}
                >
                  <Row>
                    <Col className="text-success text-center">10.0%</Col>
                    
                    <Col className="text-center">10.4%</Col>

                    <Col className="text-center">6.2%</Col>

                    <Col className="text-center">4.0%</Col>

                    <Col className="text-center">6.1%</Col>
                  </Row>
                </div>
              </div>
            </div>

            {/* <div 
              className="h-100 me-0 me-sm-3"
              style={{
                background: 'linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,1) 100%)',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                width: '15px',
              }}
            ></div> */}
          </div>
        </Row>
      
        <p className="fs-5 mb-5 text-muted">
          * Data according to Bloomberg, S&amp;P, Thomson Reuters, World Gold Council, and the Spanish Government between 1993 and 2022. All calculations assume reinvestment of dividends.
        </p>

        <div className="py-5">
          <Row className="align-items-center">
            <Col
              xs={12}
              lg={6}
              className="px-4 ps-sm-3 ps-lg-5 pe-sm-3 mb-5 mb-lg-0"
            >
              <h3 className="display-4 mb-5">
                ...and direct positive impact on our environment and communities.
              </h3>

              <Row
                className="mb-3"
                style={{
                  fontWeight: 300,
                  fontSize: '14px',
                  color: '#6C757D',
                }}
              >
                <Col
                  xs={12}
                  sm={6}
                  className="pe-4 mb-4"
                >
                  <p className="mb-2 pb-1">
                    <img 
                      src="/images/home/impact1.png"
                      srcSet="/images/home/impact1@2x.png 2x, /images/home/impact1@3x.png 3x"
                      alt=""
                    />
                  </p>

                  <p>
                    Ensuring nutritious and sufficient food by improving the sustainability and resilience of agricultural systems
                  </p>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  className="pe-5 mb-4"
                >
                  <p className="mb-2 pb-1">
                    <img 
                      src="/images/home/impact2.png"
                      srcSet="/images/home/impact2@2x.png 2x, /images/home/impact2@3x.png 3x"
                      alt=""
                    />
                  </p>

                  <p>
                    Supporting local communities and rural economies
                  </p>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  className="pe-5 mb-4"
                >
                  <p className="mb-2 pb-1">
                    <img 
                      src="/images/home/impact3.png"
                      srcSet="/images/home/impact3@2x.png 2x, /images/home/impact3@3x.png 3x"
                      alt=""
                    />
                  </p>

                  <p>
                    Mitigating the effects of climate change and promoting biodiversity
                  </p>
                </Col>

                <Col
                  xs={12}
                  sm={6}
                  className="pe-5 mb-3"
                >
                  <p className="mb-2 pb-1">
                    <img 
                      src="/images/home/impact4.png"
                      srcSet="/images/home/impact4@2x.png 2x, /images/home/impact4@3x.png 3x"
                      alt=""
                    />
                  </p>

                  <p>
                    Stopping soil degradation and improving soil carbon stock and health
                  </p>
                </Col>
              </Row>

              <Row className="mt-4 mb-3">
                <Col className="pe-2">
                  <img 
                    className="w-100 rounded-1"
                    src="/images/home/sdg-13.png"
                    alt="SDG 13: Climate action"
                  />
                </Col>

                <Col className="px-2">
                  <img 
                    className="w-100 rounded-1 me-3"
                    src="/images/home/sdg-15.png"
                    alt="SDG 15: Life on land"
                  />
                </Col>

                <Col className="px-2">
                  <img 
                    className="w-100 rounded-1 me-3"
                    src="/images/home/sdg-02.png"
                    alt="SDG 2: Zero hunger"
                  />
                </Col>

                <Col className="px-2">
                  <img 
                    className="w-100 rounded-1 me-3"
                    src="/images/home/sdg-06.png"
                    alt="SDG 6: Clean water and sanitation"
                  />
                </Col>

                <Col className="ps-2">
                  <img 
                    className="w-100 rounded-1"
                    src="/images/home/sdg-03.png"
                    alt="SDG 3: Good health and well-being"
                  />
                </Col>
              </Row>
            </Col>

            <Col
              xs={12}
              lg={6}
              className="px-0 px-sm-3 pe-lg-5 order-lg-first"
            >
              <img
                src="/images/home/whyagriculture1.jpg"
                srcSet="/images/home/whyagriculture1@2x.jpg 2x"
                alt=""
                className="color-card mw-100 rounded-3 rounded-xs-0"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
