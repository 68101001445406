import axios from 'axios'
import classNames from 'classnames'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Check, X } from 'react-feather'
import { Link } from 'react-router-dom'

import ErrorFeedback from '../components/ErrorFeedback'
import StepInput from '../components/StepInput'
import { preventSubmitOnEnter } from '../helpers'
import api from '../utils/api'

import AuthLayout from './layouts/AuthLayout'

export default function Register() {
  const abortController = new AbortController()

  const [step, setStep] = useState<number>(0)

  useEffect(() => {
    return () => abortController.abort()
    // eslint-disable-next-line
  }, [])

  return (
    <AuthLayout
      headerRight={
        step === 0 
          ? (
            <Link 
              to="/"
              className="btn btn-sm btn-outline-primary d-flex flex-row align-items-center"
            >
              <X
                size="12px"
                className="me-2"
              />

              Back to Home
            </Link>
          ) 
          : undefined
      }
    >
      <Formik
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          invest_as: '',
          company_type: '',
          interests: [] as string[],
          contactable: ''
        }}
        onSubmit={async (values, actions) => {
          try {
            const response = await api.post('/.netlify/functions/register-interest', values, { signal: abortController.signal })

            if (response !== undefined) {
              setStep(7)
            }
          }
          catch (err) {
            if (axios.isAxiosError(err) && err.response?.data?.errors !== undefined) {
              actions.setErrors(err.response.data.errors)
            }
          }
        }}
      >
        { ({ values, errors, submitForm, isSubmitting, setFieldValue, setFieldError }) =>  (
          <Form
            className="d-flex justify-content-md-center"
            onKeyDown={preventSubmitOnEnter}
          >
            { step === 0 && (
              <Col
                xs={12}
                md={11}
                lg={8}
                xl={7}
                className="pb-4 px-3 px-sm-0"
              >
                <p className="text-center mb-4">
                  <img
                    src="/images/sign.png"
                    srcSet="/images/sign@2x.png 2x, /images/sign@3x.png 3x"
                    alt="Bageera"
                    style={{ height: '50px' }}
                  />
                </p>

                <h1 className="text-center mb-3">
                  Welcome to Bageera
                </h1>

                <p className="text-center lead mb-4">
                  We are truly grateful for your interest and we are excited to give you early access as an investor.
                </p>

                <p className="text-center text-secondary mb-5">
                  We would like to ask you a few questions to get your opinion on investing in natural capital such as forestry, agriculture, biodiversity, and so on. This will help us in offering you a better product.
                </p>

                <p className="text-center text-secondary mb-5">
                  <Button
                    variant="primary"
                    className="px-5"
                    onClick={() => {
                      setStep(1)

                      setTimeout(() => {
                        const field = document.getElementById('first_name')
    
                        if (field !== null) {
                          field.focus()
                        }
                      }, 0)
                    }}
                  >
                    Continue
                  </Button>
                </p>
              </Col>
            ) }

            { step === 1 && (
              <StepInput
                step={1}
                title="What is your full name?"
                description="We build our relationships on transparency and trust, let's start with the basics."
                onBack={() => setStep(0)}
                onNext={() => {
                  if (values.first_name === '') {
                    setFieldError('first_name', 'Your first name is required.')
                  }

                  if (values.last_name === '') {
                    setFieldError('last_name', 'Your last name is required.')
                  }

                  if (values.first_name === '' || values.last_name === '') {
                    return
                  }

                  setStep(2)

                  setTimeout(() => {
                    const field = document.getElementById('email')

                    if (field !== null) {
                      field.focus()
                    }
                  }, 0)
                }}
              >
                <Row>
                  <Col
                    xs={12}
                    sm={6}
                    className="mb-3 mb-sm-0"
                  >
                    <Field
                      className={`form-control w-100 input-lg fs-2 ${errors?.first_name ? 'is-invalid' : ''}`}
                      id="first_name"
                      name="first_name"
                      type="text"
                      placeholder="First name"
                    />

                    <ErrorFeedback error={errors?.first_name} />
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                  >
                    <Field
                      className={`form-control w-100 input-lg fs-2 ${errors?.last_name ? 'is-invalid' : ''}`}
                      id="last_name"
                      name="last_name"
                      type="text"
                      placeholder="Last name"
                    />

                    <ErrorFeedback error={errors?.last_name} />
                  </Col>
                </Row>
              </StepInput>
            ) }

            { step === 2 && (
              <StepInput
                step={2}
                title="What is your email address?"
                onBack={() => setStep(1)}
                onNext={() => {
                  if (values.email === '') {
                    setFieldError('email', 'Your email address is required.')
                    return
                  }

                  if (values.email.indexOf('@') === -1) {
                    setFieldError('email', 'Your email address must be valid.')
                    return
                  }

                  setStep(3)
                }}
              >
                <Field
                  className={`form-control w-100 input-lg fs-2 ${errors?.email ? 'is-invalid' : ''}`}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Type your email address here..."
                />

                <ErrorFeedback error={errors?.email} />
              </StepInput>
            ) }

            { step === 3 && (
              <StepInput
                step={3}
                title="Are you looking to invest as an institution or an individual?"
                onBack={() => setStep(2)}
                onNext={() => {
                  if (values.invest_as === '') {
                    setFieldError('invest_as', 'Please choose an option.')
                    return
                  }

                  if (values.invest_as === 'individual') {
                    setStep(5)
                    return
                  }

                  setStep(4)
                }}
              >
                <Row className={errors?.invest_as ? 'is-invalid' : ''}>
                  <Col
                    xs={12}
                    sm={6}
                    className="mb-3 mb-sm-0"
                  >
                    <div
                      className={classNames(
                        'w-100 btn btn-lg cursor-pointer',
                        values.invest_as === 'institution' ? 'btn-outline-info' : 'btn-outline-secondary',
                        errors?.invest_as && 'border-danger'
                      )}
                      onClick={() => { setFieldValue('invest_as', 'institution') }}
                    >
                      As an institution
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                  >
                    <div
                      className={classNames(
                        'w-100 btn btn-lg cursor-pointer',
                        values.invest_as === 'individual' ? 'btn-outline-info' : 'btn-outline-secondary',
                        errors?.invest_as && 'border-danger'
                      )}
                      onClick={() => { setFieldValue('invest_as', 'individual') }}
                    >
                      As an individual
                    </div>
                  </Col>
                </Row>

                <ErrorFeedback error={errors?.invest_as} />
              </StepInput>
            ) }

            { step === 4 && (
              <StepInput
                step={4}
                title="What category does your company fit into?"
                onBack={() => setStep(3)}
                onNext={() => {
                  if (values.company_type === '') {
                    setFieldError('company_type', 'Please choose an option.')
                    return
                  }

                  setStep(5)
                }}
              >
                <div className={errors?.company_type ? 'is-invalid' : ''}>
                  { 
                    [
                      'Fund / Asset manager',
                      'Bank / Other financial institution',
                      'Non-finance related corporation',
                    ]
                      .map((item, idx) => (
                        <div
                          key={idx}
                          className={classNames(
                            'w-100 btn btn-lg mb-3 cursor-pointer',
                            values.company_type === item ? 'btn-outline-info' : 'btn-outline-secondary',
                            errors?.company_type && 'border-danger',
                          )}
                          onClick={() => { setFieldValue('company_type', item) }}
                        >
                          { item }
                        </div>
                      ))
                  }
                </div>

                <ErrorFeedback error={errors?.company_type} />
              </StepInput>
            ) }

            { step === 5 && (
              <StepInput
                step={values.invest_as === 'institution' ? 5 : 4}
                title={`Which asset classes within natural capital ${values.invest_as === 'institution' ? 'is your company' : 'are you'} more interested in?`}
                onBack={() => setStep(values.invest_as === 'institution' ? 4 : 3)}
                onNext={() => {
                  if (values.interests.length === 0) {
                    setFieldError('interests', 'Please choose at least one option.')
                    return
                  }

                  setStep(6)
                }}
              >
                <div className={errors?.interests ? 'is-invalid' : ''}>
                  { 
                    [
                      'Sustainable agriculture practices',
                      'Reforestation / Sustainable forestry management',
                      'Sustainable water production and preservation',
                      'Wildlife and biodiversity restoration',
                      'Ocean-related investments',
                      'Other',
                    ]
                      .map((item, idx) => (
                        <div
                          key={idx}
                          className={classNames(
                            'w-100 btn btn-lg mb-3 d-flex align-items-center justify-content-center cursor-pointer',
                            values.interests.includes(item) ? 'btn-outline-info' : 'btn-outline-secondary',
                            errors?.interests && 'border-danger'
                          )}
                          onClick={() => { 
                            if (values.interests.includes(item)) {
                              setFieldValue('interests', values.interests.filter(value => value !== item))
                            } else {
                              setFieldValue('interests', [...values.interests, item])
                            }
                          }}
                          style={{ outline: 'none' }}
                        >
                          { values.interests.includes(item) && (
                            <Check
                              size="16px"
                              className="me-2"
                            />
                          ) }

                          { item }
                        </div>
                      ))
                  }
                </div>

                <ErrorFeedback error={errors?.interests} />
              </StepInput>
            ) }

            { step === 6 && (
              <StepInput
                step={values.invest_as === 'institution' ? 6 : 5}
                title="Is it okay if we reach out in the future?"
                description="This will help us better understand your needs and motivations to offer exactly what you are looking for."
                onBack={() => setStep(5)}
                textNext="Send"
                onNext={async () => {
                  if (values.contactable === '') {
                    setFieldError('contactable', 'Please choose an option.')
                    return
                  }

                  submitForm()
                }}
                isSubmitting={isSubmitting}
              >
                <Row className={errors?.contactable ? 'is-invalid' : ''}>
                  <Col
                    xs={12}
                    sm={6}
                    className="mb-3"
                  >
                    <div
                      className={classNames(
                        'w-100 btn btn-lg cursor-pointer',
                        values.contactable === 'true' ? 'btn-outline-info' : 'btn-outline-secondary',
                        errors?.contactable && 'border-danger'
                      )}
                      onClick={() => { setFieldValue('contactable', 'true') }}
                    >
                      Yes, no problem
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    sm={6}
                    className="mb-3"
                  >
                    <div
                      className={classNames(
                        'w-100 btn btn-lg cursor-pointer',
                        values.contactable === 'false' ? 'btn-outline-info' : 'btn-outline-secondary',
                        errors?.contactable && 'border-danger'
                      )}
                      onClick={() => { setFieldValue('contactable', 'false') }}
                    >
                      No, thanks
                    </div>
                  </Col>
                </Row>

                <ErrorFeedback error={errors?.contactable} />
              </StepInput>
            ) }

            { step === 7 && (
              <Col
                xs={12}
                md={11}
                lg={8}
                xl={7}
                className="pb-4"
              >
                <p className="text-center mb-4">
                  <Check size="32px" />  
                </p>

                <h1 className="text-center mb-4">
                  Thank you!
                </h1>

                <p className="text-center lead px-4 mb-4">
                  Thanks for registering your interest in Bageera.<br /> We will get back to you very soon.
                </p>

                <p className="text-center lead">
                  <Link
                    to="/"
                    className="btn btn-primary px-5"
                  >
                    Back to Home
                  </Link>
                </p>
              </Col>
            ) }
          </Form>
        ) }
      </Formik>
    </AuthLayout>
  )
}
